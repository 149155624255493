import React from 'react';
import Container from '~components/container/container.component';
import { Link } from 'gatsby';

import styles from './page-404-content.module.scss';

const PageNotFoundContent = () => (
  <Container>
    <div className={styles.wrapper}>
      <h1>Oops! You have encountered the 404 error page!</h1>
      <p>Why? Because we can’t seem to find the page you are looking for. It is either unavailable or may no longer exist.</p>
      <p>Please try using the <Link to='/site-map/'>Site Map</Link> to find what you’re looking for.</p>
    </div>
  </Container>
);

export default PageNotFoundContent;
