import React from 'react';
import SEO from '~components/seo';
import Header from '~components/header/header.component';
import PageNotFoundContent from '~components/page-404-content/page-404-content.component';

const PageNotFound = () => (
  <>
    <SEO title="Page Not Found" />
    <Header />
    <main>
      <PageNotFoundContent />
    </main>
  </>
);

export default PageNotFound;
